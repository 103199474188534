import { useEffect, useState } from "react";
import apiClient from "../utils/axios";

const Dashboard = () => {

	const [membersCount, setMembersCount] = useState(0)
	const [membersActiveCount, setMembersActiveCount] = useState(0)
	const [membersInActiveCount, setMembersInActiveCount] = useState(0)
	const[cooperativesCount,setCooperativesCount] = useState(0)
	
	const getData = async () => {
		const response = await apiClient.get('v1/get-dashboard-stats')
		if (response.status && response.data.status) {
			console.log("response",)
			setMembersCount(response.data.membersCount)
			setMembersActiveCount(response.data.activeMembersCount)
			setMembersInActiveCount(response.data.inActiveMembersCount)
			setCooperativesCount(response.data.cooperativesCount)
		}
	}
	useEffect(() => {
		getData()
	},[])
    return <div class="app-content-area">
					<div class="pt-10 pb-10 mt-n6 mx-n4"></div>
					<div class="container-fluid mt-n22">
						
						<div class="row">
							<div class="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">
								<div class="card h-100 card-lift">
									<div class="card-body">
										<div class="d-flex justify-content-between align-items-center mb-3">
											<div>
												<h4 class="mb-0">Members</h4>
											</div>
											<div class="icon-shape icon-md bg-primary-soft text-primary rounded-2">
												<i data-feather="briefcase" height="20" width="20"></i>
											</div>
										</div>
										<div class="lh-1">
								<h1 class="mb-1 fw-bold">{ membersCount}</h1>
											<p class="mb-0">
												{/* <span class="text-dark me-2">2</span> */}
												Members
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">
								<div class="card h-100 card-lift">
									<div class="card-body">
										<div class="d-flex justify-content-between align-items-center mb-3">
											<div>
												<h4 class="mb-0">Active Members</h4>
											</div>
											<div class="icon-shape icon-md bg-primary-soft text-primary rounded-2">
												<i data-feather="list" height="20" width="20"></i>
											</div>
										</div>
										<div class="lh-1">
											<h1 class="mb-1 fw-bold">{membersActiveCount}</h1>
											<p class="mb-0">
												{/* <span class="text-dark me-2">28</span> */}
												Members
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">
								<div class="card h-100 card-lift">
									<div class="card-body">
										<div class="d-flex justify-content-between align-items-center mb-3">
											<div>
												<h4 class="mb-0">Cooperatives</h4>
											</div>
											<div class="icon-shape icon-md bg-primary-soft text-primary rounded-2">
												<i data-feather="users" height="20" width="20"></i>
											</div>
										</div>
										<div class="lh-1">
											<h1 class="mb-1 fw-bold">{ cooperativesCount}</h1>
											<p class="mb-0">
												{/* <span class="text-dark me-2">1</span> */}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">
								<div class="card h-100 card-lift">
									<div class="card-body">
										<div class="d-flex justify-content-between align-items-center mb-3">
											<div>
												<h4 class="mb-0">Productivity</h4>
											</div>
											<div class="icon-shape icon-md bg-primary-soft text-primary rounded-2">
												<i data-feather="target" height="20" width="20"></i>
											</div>
										</div>
										<div class="lh-1">
											<h1 class="mb-1 fw-bold">76%</h1>
											<p class="mb-0">
												<span class="text-success me-2">5%</span>
												Productivity
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>
}
export default Dashboard;