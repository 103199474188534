import React, { useState } from 'react';
import apiClient from '../../utils/axios';
import { useNavigate } from 'react-router-dom';

const EditSingleMember = ({ memberData, varieties }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdatingMember,setIsUpdatingMember] = useState(false)
  const [member, setMember] = useState(memberData);
  const [updatedFarms, setUpdatedFarms] = useState(memberData?.farms || []);
  const [isSubmittingFarm, setIsSubmittingFarm] = useState(new Array(updatedFarms.length).fill(false))
  const navigate = useNavigate()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMember({
            ...member,
            [name]: value,
        });
    };

  const handleFarmChange = (e, index) => {
        const { name, type, checked, value } = e.target;
        const updatedFarmsList = [...updatedFarms];
        updatedFarmsList[index] = {
            ...updatedFarmsList[index],
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
        };
        setUpdatedFarms(updatedFarmsList);
  };

  const handleFarmUpdate = async (index) => {
        const newSubmittingState = [...isSubmittingFarm];
        newSubmittingState[index] = true;
        setIsSubmittingFarm(newSubmittingState);
    
        const updatedFarm = updatedFarms[index];
        let data = {
          ...updatedFarm,
          onlyFarm:true
    }
    
      // You can add your API call here to update the farm
      try {
        
        const response = await apiClient.put(`v1/members/${member.id}`, data)
        if (response.data.status) {
            // setIsSuccess(true)
              // Simulate API call or async operation
            setTimeout(() => {
                // console.log('Updating farm:', updatedFarm);
                 const newSubmittingState = [...isSubmittingFarm];
                // Revert loading state after operation
                newSubmittingState[index] = false;
                setIsSubmittingFarm(newSubmittingState);
            }, 2000); 
        }
      } catch (error) {
        
      }
    };
  
  


    const submitForm = async (e) => {
      e.preventDefault();
      
      setIsUpdatingMember(true)

      let data = {
        member,
        onlyFarm: false
      }
      try {
        const response = await apiClient.put(`v1/members/${member.id}`, data)
        if (response.data.status) { 
          setIsUpdatingMember(false);
          setIsSuccess(true)
          setTimeout(() => {
            setIsSuccess(false)
          }, 2000);
        }

      } catch (error) {
        console.log("error",error)
      }
        // handleSubmit(member);
    };

    // const getCoordinates = (index) => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             const lat = position.coords.latitude;
    //             const long = position.coords.longitude;
    //             let geo = `${lat},${long}`;
    //             const updatedFirms = updatedFarms.map((firm, i) =>
    //                 i === index ? {
    //                     ...firm, 
    //                     gis_coordinates:geo
    //                 } : firm
    //             );
    //             setUpdatedFarms(updatedFirms);
    //         });
    //     } else {
    //         console.log("Geolocation is not supported by this browser.");
    //     }
    
        

    // }

    const getCoordinates = (index) => {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true, //request the best accuracy
                timeout: 10000, // wait for 10 seconds for a location
                maximumAge: 0, //prevent using a  cached positioning
            };

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const long = position.coords.longitude;
                    let geo = `${lat},${long}`;

                    // Update the gis_coordinates for the selected firm
                    const updatedFirms = updatedFarms.map((firm, i) =>
                        i === index ? { ...firm, gis_coordinates: geo } : firm
                    );
                    console.log(`Updated coordinates for Firm ${index}: ${geo}`);
                    setUpdatedFarms(updatedFirms);
                },
                (error) => {
                    console.error("Error getting location", error);
                },
                options
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };


   const farmsData = updatedFarms.length > 0 && (
     <div className='table-responsive'>
        <table className="table table-bordered mt-3">
                    <thead className="table-dark" >
                        <tr>
                            <th style={{ color:'#e5eaf1' }}>Farm</th>
                            <th style={{ color:'#e5eaf1' }}>GIS Coordinates</th>
                            <th style={{ color:'#e5eaf1' }}>Land Near Forest</th>
                            <th style={{ color:'#e5eaf1' }}>Land Near Shopping Centre</th>
                            <th style={{ color:'#e5eaf1' }}>Land Size(hectares)</th>
                            <th style={{ color:'#e5eaf1' }}>No of Bushes</th>
                            <th style={{ color:'#e5eaf1' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {updatedFarms.map((farm, index) => (
                            <tr key={farm.id}>
                                <td>Farm {index + 1}</td>
                                <td>
                                    {!farm.gis_coordinates ? <span className='btn btn-primary btn-sm' onClick={()=>getCoordinates(index)}>get coordinates</span> : <input
                                        type="text"
                                        className="form-control"
                                        name="gis_coordinates"
                                        value={farm?.gis_coordinates || ''}
                                        onChange={(e) => handleFarmChange(e, index)}
                                    />}
                                </td>
                                <td>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="land_near_forest"
                                            checked={farm?.land_near_forest === 1}
                                            onChange={(e) => handleFarmChange(e, index)}
                                        />
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="land_near_shopping_centre"
                                            checked={farm?.land_near_shopping_centre === 1}
                                            onChange={(e) => handleFarmChange(e, index)}
                                        />
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                </td>
                                <td>
                                    <input
                                        style={{padding:'0px'}}
                                        type="text"
                                        className="form-control"
                                        name="land_size"
                                          value={farm?.land_size || ''}
                                        onChange={(e) => handleFarmChange(e, index)}
                                    />
                                </td>
                                <td>
                                    <input
                                        style={{padding:'0px'}}
                                        type="text"
                                        className="form-control"
                                        name="no_of_bushes"
                                        value={farm?.no_of_bushes || ''}
                                        onChange={(e) => handleFarmChange(e, index)}
                                    />
                                </td>

                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                onClick={() => handleFarmUpdate(index)}
                                 disabled={isSubmittingFarm[index]}
                                    >
                                     {!isSubmittingFarm[index] ? "Update Farm" : "please wait .."}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
               </div>
            )


    return (
      <div  className="container mt-4">
        <div className='mt-2 mb-2'>
          Edit {member.first_name}
        </div>
         <form onSubmit={submitForm}>
            <div  className='row'>
              <div className="row mb-3">
                  <div className="col-md-6">
                      <label className="form-label">Member Number:</label>
                      <input
                          type="text"
                          className="form-control"
                          name="member_number"
                          value={member?.member_number || ''}
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="col-md-6">
                      <label className="form-label">Phone:</label>
                      <input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={member?.phone || ''}
                          onChange={handleInputChange}
                      />
                  </div>
              </div>

              <div className="row mb-3">
                  <div className="col-md-4">
                      <label className="form-label">First Name:</label>
                      <input
                          type="text"
                          className="form-control"
                          name="first_name"
                          value={member?.first_name || ''}
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="col-md-4">
                      <label className="form-label">Middle Name:</label>
                      <input
                          type="text"
                          className="form-control"
                          name="middle_name"
                          value={member?.middle_name || ''}
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="col-md-4">
                      <label className="form-label">Last Name:</label>
                      <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          value={member?.last_name || ''}
                          onChange={handleInputChange}
                      />
                  </div>
              </div>

              <div className="row mb-3">
                  <div className="col-md-6">
                      <label className="form-label">ID Number:</label>
                      <input
                          type="text"
                          className="form-control"
                          name="id_number"
                          value={member?.id_number || ''}
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="col-md-6">
                      <label className="form-label">Gender:</label>
                      <select
                          className="form-select"
                          name="gender"
                          value={member?.gender || ''}
                          onChange={handleInputChange}
                      >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                      </select>
                  </div>
              </div>

              <div className="row mb-3">
                  <div className="col-md-6">
                      <label className="form-label">Email:</label>
                      <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={member?.email || ''}
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="col-md-6">
                      <label className="form-label">Membership Status:</label>
                      <select
                          className="form-select"
                          name="membership_status"
                          value={member?.membership_status || ''}
                          onChange={handleInputChange}
                      >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                      </select>
                  </div>
            </div>
            
            <div className="mt-4 text-center">
              <button type="submit" className="btn btn-primary">{!isUpdatingMember ? "Update Member" : "please wait..."}</button>
              {isSuccess && <p className='text-success fw-bold'>Successfully updated member</p>}
            </div>
          </div>



          <div className='row'>
            {/* Farms */}
            {farmsData}
          </div>
            

            
        </form>
       </div>
    );
};

export default EditSingleMember;
