import { useState } from "react";
import apiClient from "../../../utils/axios";
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";
import { membersExcelSheet } from "../../../constants/membersExcelSheet";



const BulkUploadForm = ({closeBulkUploadForm}) =>{

    const [file,setFile] = useState(null);
    const [data,setData] = useState([])
    const [error,setError] = useState("")
    const [message, setMessage] = useState("")
    const [viewData, setViewData] = useState(false)
    const navigate = useNavigate()



    const isExcelFile = (uploadedFile) => {
        return uploadedFile.name.endsWith(".xlsx") || uploadedFile.name.endsWith(".xls");
    };

    const downloadSheetHandler = () =>{

        const data = membersExcelSheet;

        // Convert the data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "members-sheet.xlsx");

    }

    const handleFileUpload = (e) => {
        
        if(e.target.value === ""){
            return ;
        }
       
       
       const uploadedFile  =  e.target.files[0]

        if (!isExcelFile(uploadedFile)) {
            setError('Invalid file type. Please upload an Excel file.');
            setFile(null)
            setData([])
            setViewData(false)
            return;
        } else{
            setViewData(true)
            setError("")
            setFile(uploadedFile)
        }
        
    }


    const viewFileData = (e)=> {
        e.preventDefault();
        if (!file) return;
        if (error) return;
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            setData(jsonData);
        };
        reader.readAsBinaryString(file);

    }

    const handleSendData = async () => {

        if(data.length === 0 ) return ;
        //
        try {
            const response = await apiClient.post('v1/members/bulk-upload-members', { data });
            if(response.data.status){
                setMessage("Success")
                setTimeout(() => {
                    setMessage()
                   
                    navigate("/members/list-members")
                }, 2000);
                
                setFile(null)
                
            }else{
                setMessage("")
                setError(response.data.message)
                // setTimeout(() => {
                //     setError("")
                    
                // }, 5000);
                

                
            }
               
        } catch (error) {
            console.error('Error sending data to the backend', error);
        }
    };



    const closeBulkUploadFormHandler = () =>{
        closeBulkUploadForm()
    }
    return <div className="container mt-4">
            <div onClick={closeBulkUploadFormHandler} className='btn btn-danger'>Close Form</div>
            <div className="mb-3">
                <form action="#" className="mt-4">
                        <div className="row g-gs">

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="childName">Upload File</label>
                                    <div className="form-control-wrap">
                                    <input
                                        type="file"
                                        className="form-control"
                                        placeholder="upload file"
                                        onChange={handleFileUpload}
                                    />
                                                </div>
                                                
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label" htmlFor="childName">Download Sheet</label>
                                <div className="form-control-wrap">
                                    <span className="btn btn-primary" onClick={downloadSheetHandler}>Download Excel Sheet</span>
                                </div>
                                </div>
                            </div>

                            <div className="pt-3 d-flex gap-2 pb-3">

                                <span className="btn btn-danger" onClick={closeBulkUploadFormHandler}>Cancel</span>
                                    
                                { viewData  && <span  className="btn btn-primary" onClick={viewFileData}>View Data</span> }
                                {data.length > 0 &&  <span className="btn btn-primary" onClick={handleSendData}>Upload</span> } 

                            </div>

                            {data.length > 0 && (
                                <div className="table-responsive" style={{height:'300px'}}>
                                    <div className="d-flex justify-content-between">
                                        <div><b>Total Members: {data.length} </b></div>
                                        <div>
                                            {message && <div className="text-success m-3">{message}</div>}
                                            {error && <div  className="text-danger"><b>{ error}</b></div>}       
                                        </div>
                                    </div>
                                <table className="table">
                                <thead>
                                    <tr>
                                    {Object.keys(data[0]).map((key) => (
                                        <th key={key}>{key}</th>
                                    ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((value, idx) => (
                                        <td key={idx}>{value}</td>
                                        ))}
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                                </div>
                            )}


                        </div>
                </form>
            </div>
        </div>
    
  
}
export default BulkUploadForm