import {  useState } from "react"
import Button from "../../reusables/Button";
import apiClient from "../../utils/axios";
import { useAuth } from "../../store/AuthContext";


const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const {  isUserLoggedInHandler } = useAuth();
    

   
    const submitHandler = async (e) => {
      e.preventDefault();
      setIsSubmitting(true)
        
        if (email === "" || password === "") {
            setError("please fill all the fileds * ");
        }
        let data = {
            email: email,
            password:password
        }
        
        try {
            let url = "v1/login-user"
            const response = await apiClient.post(url, data);  
            console.log("response on login",response.data.status)
            
            if (!response.data.status) {
                setError("wrong login credentials")
            } else {
              setError("")
              setIsSubmitting(false)
              isUserLoggedInHandler(response.data)
            }
        
        } catch (error) {
            console.log("an error occured",error)
        }

    }

  



    return <main className="container d-flex flex-column">
      <div className="row align-items-center justify-content-center g-0 min-vh-100">
        <div className="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
          <div className="card smooth-shadow-md">
            <div className="card-body p-6">
              <div className="mb-4">
                {/* <a href="../index.html">
                  <img
                    src="../assets/images/brand/logo/logo-2.svg"
                    className="mb-2 text-inverse"
                    alt="Image"
                  />
                </a> */}
                <h5 className="text-center fw-bold">Coffee Farmers Database</h5>
                <p className="mb-6 text-center">(Please enter your user information.)</p>
              </div>
              <form onSubmit={submitHandler}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    name="email"
                    placeholder="Email address here"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    name="password"
                    placeholder="**************"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-lg-flex justify-content-between align-items-center mb-4">
                  <div className="form-check custom-checkbox">
                    <input type="checkbox" className="form-check-input" id="rememberme" />
                    <label className="form-check-label" htmlFor="rememberme">Remember me</label>
                  </div>
                </div>
                <div className="d-grid">
                  {!isSubmitting && <Button type="submit">Sign in</Button>}
                  {isSubmitting && <Button type="submit" disabled>Please wait..</Button>}
                  {error !== "" && <span className="text-danger">{ error }</span>}
                  
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>


     
}

export default Login