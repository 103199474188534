import { useNavigate } from "react-router-dom";

const ShowMember = ({member}) =>{

    const navigate = useNavigate()
    const goBackHandler = () => {
        navigate('/members/list-members')
    }

    return <div>
        <div className="btn btn-primary" onClick={goBackHandler}>Back to Members</div>
            <div className="mt-4">
                <div className="card shadow">
                    <div className="card-body">
                    <h4 className="card-title">Member Profile</h4>
                    <div className="row">
                        {/* Member Details */}
                        <div className="col-md-6">
                        <h5>Personal Information</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><strong>Member Number:</strong> {member.member_number}</li>
                            <li className="list-group-item"><strong>Name:</strong> {`${member?.first_name} ${member?.middle_name} ${member?.last_name}`}</li>
                            <li className="list-group-item"><strong>ID Number:</strong> {member.id_number}</li>
                            <li className="list-group-item"><strong>Gender:</strong> {member.gender}</li>
                            <li className="list-group-item"><strong>Phone:</strong> {member.phone}</li>
                            <li className="list-group-item"><strong>Email:</strong> {member.email ? member.email : 'N/A'}</li>
                            <li className="list-group-item"><strong>Membership Status:</strong> {member.membership_status}</li>
                        </ul>
                        </div>

                        {/* Farm Information */}
                        <div className="col-md-6">
                        <h5>Farms Information</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover">
                                <thead className="thead-light">
                                <tr>
                                    <th>GIS Coordinates</th>
                                    <th>Land Near Forest</th>
                                    <th>Land Near Shopping Centre</th>
                                    <th>Land Size</th>
                                </tr>
                                </thead>
                                <tbody>
                                {member.farms.length > 0 && member.farms.map((farm, index) => (
                                    <tr key={index}>
                                    <td>
                                        <a
                                            href={`https://www.google.com/maps?q=${farm.gis_coordinates}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                        {farm.gis_coordinates}
                                        </a>
                                    </td>
                                    <td>{farm.land_near_forest ? 'Yes' : 'No'}</td>
                                    <td>{farm.land_near_shopping_centre ? 'Yes' : 'No'}</td>
                                    <td>{farm.land_size} hectares</td>
                                    </tr>
                                ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
    </div>
}
export default ShowMember;