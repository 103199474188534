const initialState = {
    name: null,
    cart:[]
}
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_NAME':
            return {
                ...state,name:action.payload
            }
            break;
        case 'ADD_TO_CART':
            return {
                ...state,cart:[...state.cart,action.payload]
            }
    
        default:
            return state
            break;
    }

}