
import { React } from 'react';
import apiClient from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import CreateProduction from '../../components/productions/CreateProduction';

const AddProductions = () => {

    const navigate = useNavigate();

    const handleSubmit = async () =>{
        let data = {

        }
        const response = await apiClient.post(data)
        if(response.status === 200){
            navigate("/list-members")
        }
    } 

    return <CreateProduction/>
}

export default AddProductions;