import { NavLink, useNavigate } from "react-router-dom";
import apiClient from "../../utils/axios";
import { useState } from "react";
import { ReactPaginate } from 'react-paginate';

const AllProductions = ({productions,count,pageNoHandler}) =>{
    
    const [successMessage,setSuccessMessage]= useState("")

    const navigate = useNavigate()

    const pageHandler = (data) => {
        console.log(data)
    }

    const handleDelete =  async (id) =>{
        
        const response  =  await apiClient.delete(`productions/delete/${id}`)
        if(response.status ===  200){
            setSuccessMessage("Successfuuly deleted Production")
            setTimeout(()=>{
                setSuccessMessage("")
            },3000)
        }
    }

    if(count === 0 ){
        return <p>No Productions</p>
    }
    return <div className="table-responsive">
             <h3>Productions :({count})</h3>
             
             <p className="text-center text-danger">{successMessage}</p>
          
        <div class="card mb-4">
            <div class="card-header">
                <div class="row g-2">
                <div class="col-lg-6 col-md-5 d-grid d-lg-block">
                    <NavLink to="/productions/add-production" className="btn btn-primary">+ Create New Production</NavLink>
                </div>
                <div class="col-md-7 col-lg-4">
                    <input type="search" class="form-control w-100" placeholder="Search for Invoice Number, Customer Name" />
                </div>
                <div class="col-lg-2 d-flex">
                    <select class="form-select">
                    <option selected="">Status</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </select>
                    <a href="#!" class="btn btn-danger-soft  btn-icon ms-2 texttooltip" data-template="trashOne">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 icon-xs"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    <div id="trashOne" class="d-none">
                    <span>Delete</span>
                    </div>
                </a>

                </div>
                </div>
            </div>

            <div class="card-body">
    <div class="table-responsive table-card">
      <table class="table text-nowrap mb-0 table-centered">
        <thead class="table-light">
          <tr>
            <th class="pe-0">
                <div class="form-check">
                    <input class="form-check-input" id="checkAll" type="checkbox" value="" />
                    <label class="form-check-label" for="checkAll">
                    </label>
                </div>
            </th>
            <th class="ps-0">ID</th>
            <th>Coffee production volume</th>
            <th>Percement</th>
            <th>Green coffee grade</th>
            <th>Green coffee volume</th>
            <th>Harvest date</th>
            <th>Member Name</th>
            
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
            {productions.map(production => (<tr>
                <td class="pe-0">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="contactCheckbox7" />
                        <label class="form-check-label" for="contactCheckbox7">
                        </label>
                    </div>
                </td>
                <td class="ps-0">
                    <a href="#!">{ production.id}</a>
                </td>
                <td>{production.coffee_production_volume}</td>
                <td>{ production.percement	}</td>
                <td>{ production.green_coffee_grade}</td>
                <td>{ production.green_coffee_volume }</td>
                <td>{production.harvest_date}</td>
                <td>{ production?.member?.name}</td>
                <td>
                    <div>
                        <div class="dropdown">
                            <a class="btn btn-ghost btn-icon btn-sm rounded-circle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical icon-xs"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                            </a>

                            <div class="dropdown-menu">

                                <a class="dropdown-item d-flex align-items-center" href="#!">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye me-2 icon-xs"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>View
                                </a>



                                <a class="dropdown-item d-flex align-items-center" href="#!">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit me-2 icon-xs"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>Edit
                                </a>



                                <a class="dropdown-item d-flex align-items-center" href="#!">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download me-2 icon-xs"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>Download
                                </a>


                                <a class="dropdown-item d-flex align-items-center" href="#!">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 me-2 icon-xs"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>Delete
                                </a>

                            </div>
                        </div>
                    </div>
                </td>
            </tr>))}
        </tbody>           
                    </table>
                    
        <div className="mt-3">
            {count > 10 &&
            <div className="mt-3 mb-2">
            <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={(count / 10)}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={pageHandler}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    /> 
                </div>
            }
        </div>

                    
                   
    </div>
  </div>

        </div>
        
    </div>

}
export default AllProductions;