import {  HashRouter  as Router, Route, Routes, useNavigate } from "react-router-dom";

import Login from "../pages/auth/Login";

// import  useCheckRole  from "../reusables/CheckRoleOrPermission";
import AddMembers from './../pages/members/AddMembers';
import ListMembers from "../pages/members/ListMembers";
import Dashboard from "../pages/Dashboard";
import EditMember from "../pages/members/EditMember";
import ViewMember from "../pages/members/ViewMember";
import ListProductions from "../pages/productions/ListProductions";
import AddProductions from "../pages/productions/AddProductionss";
import { useAuth } from "../store/AuthContext";
import MemberView from "../pages/members/MemberView";


const NavPage = () => {

    const {isLoggedIn} = useAuth()
   
    return (
        <Routes>
            
            {isLoggedIn && <Route path="/dashboard" element={<Dashboard />} />}
            {isLoggedIn && <Route path="/members/add-member" element={<AddMembers />} />}
            {isLoggedIn && <Route path="/members/list-members" element={<ListMembers />} />}
            {isLoggedIn && <Route path="/members/edit/:id" element={<EditMember />} />}
            {isLoggedIn && <Route path="/members/view/:id" element={<MemberView />} />}
            {isLoggedIn && <Route path="/productions/list-productions" element={<ListProductions />} />}
            {isLoggedIn && <Route path="/productions/add-production" element={<AddProductions />} />} 
           
        
            
        </Routes>
    );
}

export default NavPage;


